import React from 'react';

function Updates() {
  return (
    <div> 
<div className="search-results-container">
        <br />
        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">Release - 03.18.24</h1>
          <ul class="list-disc">
          <li>Added updated Windows hashes from latest OS releases</li>
          <ol class="ps-5 mt-2 space-y-1 list-disc list-inside">
          <li>Windows 10+11 Enterprise, Desktop and Server 2022+2016, <strong>Hashes Added: 520</strong></li>
          </ol>
          </ul>
        </div>

        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">Release - 02.11.24</h1>
          <ul class="list-disc">
          <li>Added updated Windows hashes from latest OS releases</li>
          <ol class="ps-5 mt-2 space-y-1 list-disc list-inside">
          <li>Windows 10+11 Enterprise, Desktop and Server 2022 DC, Total Hashes: 4865, Duplicates: 4405, <strong>Hashes Added: 460</strong></li>
          </ol>
          </ul>
        </div>

        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">Release - 12.29.23</h1>
          <ul class="list-disc">
          <li>Added updated Windows hashes from latest OS releases</li>
          <ol class="ps-5 mt-2 space-y-1 list-disc list-inside">
          <li>Windows 10+11 Enterprise, Total Hashes: 2508, Duplicates: 2222, <strong>Hashes Added: 286</strong></li>
          </ol>
          </ul>
        </div>

        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">Release - 12.15.23</h1>
          <ul class="list-disc">
          <li>Added latest NIST dump (2023.12.1 Delta)</li>
          <ol class="ps-5 mt-2 space-y-1 list-disc list-inside">
          <li><strong>Hashes added: 3,330</strong></li>
          </ol>
          </ul>
        </div>

        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">Release - 11.25.23</h1>
          <ul class="list-disc">
          <li>Added updated windows hashes from latest OS releases</li>
          <ol class="ps-5 mt-2 space-y-1 list-disc list-inside">
          <li>Windows 10+11 Enterprise, Total Hashes: 2377, Duplicates: 2170, <strong>Hashes Added: 1390</strong></li>
          </ol>
          </ul>
        </div>

       </div>
      </div>
  );
}

export default Updates;
