import React, { useState, useEffect } from 'react';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';
import Updates from './components/Updates';
import API from './components/API';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(5);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearchSubmit = async (query) => {
    setIsLoading(true);

    try {
      const startIndex = (currentPage - 1) * resultsPerPage;
      const endIndex = startIndex + resultsPerPage;

      const { data, error } = await supabase
        .from('goodware')
        .select('*')
        .or(`md5.eq.${query},sha256.eq.${query},file_name.ilike.%${query}%`);

      if (error) throw error;
      setSearchResults(data);
      setHasSearched(true); 
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <BrowserRouter> 
      <nav className="navbar text-white p-4"> {/* Example navigation  */} 
        <h1 className="text-xl font-bold mb-2 mr-8">goodware.io</h1> {/* Project title */}
        <div className="links-container">
          <NavLink to="/" className="mr-4">Search</NavLink> 
          <NavLink to="/updates" className="mr-4">Updates</NavLink> 
          <NavLink to="/api">API</NavLink>
	</div> 
      </nav>

      <Routes> 
        <Route path="/" element={ 
            <div className="container mx-auto p-4"> {/* Search route content */}
              <SearchForm onSearchSubmit={handleSearchSubmit} />
              {isLoading ? (
                <div className="text-center spinner-container">
                  <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                </div>
              ) : (
                <SearchResults
                  results={searchResults}
                  currentPage={currentPage}
                  resultsPerPage={resultsPerPage}
                  onPageChange={handlePageChange}
                  hasSearched={hasSearched} 
                />
              )}
            </div> 
          } />
        <Route path="/updates" element={<Updates />} />
        <Route path="/api" element={<API />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

