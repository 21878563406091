import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'; 

const SearchForm = ({ onSearchSubmit }) => {
 const [searchQuery, setSearchQuery] = useState('');

 const handleSubmit = (e) => {
  e.preventDefault();
  onSearchSubmit(searchQuery);
  setSearchQuery('');
 };

 return (
  <div className="search-form-container">
   <form onSubmit={handleSubmit} class="bg-zinc-900 shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <input
     type="text"
     value={searchQuery}
     onChange={(e) => setSearchQuery(e.target.value)}
     placeholder="Enter md5, sha256, or file"
     className="input-modified"
    />
	<button type="submit">
      <FontAwesomeIcon icon={faSearch} /> 
    </button>
   </form>
  </div>
 );
};

export default SearchForm;
