import React from 'react';

function API() {
  return (
    <div> 
<div className="search-results-container">
        <br />
        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">What is the purpose of this API?</h1>
          <p>
            Goodware is a dataset that lists all known "good" hashes off of base operating systems. For goodware.io we focus on the Windows operating system. This API could be utilized to hook into various
            things, but one that gets a lot of use is SOAR products. You can enrich, and also make decisions based off "good" file hashes, or even skip parts of an investigation. 
          </p>
        </div>

        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4">  {/* Tailwind classes added */}
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">API usage instructions</h1>
          <div class="bg-orange-100 border-l-4 border-orange-500 rounded text-orange-700 p-4" role="alert">
            <p class="font-bold">Important</p>
            <p>The API has been updated to include a new URI path, and an authorization key.</p>
            </div>
            <br />
            <h2 class="text-2xl font-extrabold dark:text-white">md5 hash lookup</h2> <br />
            <p class="break-words">curl -s https://api.goodware.io/rest/v1/goodware?md5=eq.3b2f8ae0c385b91cfa177547a3401f90&apikey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVscW9kZ2RhYXRvYmZnemVvYmVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ3NjE2MDQsImV4cCI6MjAyMDMzNzYwNH0.Y8Gy-P2FwE0EQQnf3o7giCf7E9nahlHW-kB5EptXi0k | jq </p> <br />
            <h2 class="text-2xl font-extrabold dark:text-white">sha256 hash lookup</h2> <br />
            <p class="break-words">curl -s https://api.goodware.io/rest/v1/goodware?sha256=eq.b4babca03d08284a89436b8abfc37a6e40ff9c92614d8e1b8adb4de7f6886260&apikey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVscW9kZ2RhYXRvYmZnemVvYmVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ3NjE2MDQsImV4cCI6MjAyMDMzNzYwNH0.Y8Gy-P2FwE0EQQnf3o7giCf7E9nahlHW-kB5EptXi0k | jq </p><br />
            <h2 class="text-2xl font-extrabold dark:text-white">filename lookup</h2> <br />
            <p class="break-words">curl -s https://api.goodware.io/rest/v1/goodware?file_name=eq.explorer.exe&apikey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVscW9kZ2RhYXRvYmZnemVvYmVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ3NjE2MDQsImV4cCI6MjAyMDMzNzYwNH0.Y8Gy-P2FwE0EQQnf3o7giCf7E9nahlHW-kB5EptXi0k | jq </p>
        </div>

        <div className="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4"> 
        <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl text-white">API output example</h1>
          <pre> {/* Using <pre> for formatted JSON-like output */}
{`   {
    "id": "120771d1-66ec-479c-8b32-7db06f770d11",
    "md5": "ffa764631cb70a30065c12ef8e174f9f",
    "sha256": "b5559af2bb51134acea925ee633eb60d804e57848c1587ce0f9ff026e426d5f1",
    "file_name": "explorer.exe",
    "created_at": "2024-02-11T17:58:42.699539+00:00",
    "updated_at": "2024-02-11T18:08:45.25963+00:00",
    "completed_at": null,
    "ai_defined": "Explorer.exe is the executable file for Windows Explorer, the graphical user interface (GUI)..."
    }`}
          </pre>
        </div>
        </div>
      </div>
  );
}

export default API;
