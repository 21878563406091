import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const SearchResults = ({ results, currentPage, resultsPerPage, onPageChange, hasSearched }) => {  

  // Logic for determining when to show "Next" and "Previous" buttons
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage * resultsPerPage < results.length;

  // Slice the results array for the current page
  const resultsToDisplay = results.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );

  return (
    <div className="search-results-container">
      {hasSearched && !results?.length && ( // Check if searched and no results
        <div className="search-noresults">No results found</div> 
      )}

{resultsToDisplay.map((result) => (
    <div key={result.id} class="w-full p-4 rounded-lg shadow shadow-zinc-950/50 sm:p-8 dark:bg-zinc-900 dark:border-gray-700 mb-4"> 
        {/* Use Tailwind's card-like classes */}
        <p class="break-words"><FontAwesomeIcon icon={faFile} /> {result.file_name}</p>
        <p class="break-words"><FontAwesomeIcon icon={faHashtag} /> {result.md5}</p>
        <p class="break-words"><FontAwesomeIcon icon={faHashtag} /> {result.sha256}</p>
        <p class="break-words"><FontAwesomeIcon icon={faClock} /> {result.updated_at}</p>
        <p class="break-words"><FontAwesomeIcon icon={faRobot} /> {result.ai_defined}</p>
    </div>
))}
      <div className="pagination">
         {hasPreviousPage && (
           <button 
             class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" 
             onClick={() => onPageChange(currentPage - 1)}
           >
             Previous
           </button>
         )}
         {hasNextPage && (
           <button 
             class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" 
             onClick={() => onPageChange(currentPage + 1)}
           >
             Next
           </button>
         )}
       </div>
    </div>
  );
};

export default SearchResults;

